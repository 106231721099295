import { FC, useEffect, useState } from "react";
import Container from "../../components/footer/Container";
import "./About.scss";
import { useAppDispatch } from "../../store";
import { fetchAbout } from "../../store/varia";
import { useAbout } from "../../store/varia/hooks";
import aboutImg from "./../../assets/about.png";
import teamImg from "./../../assets/team.png";
import aboutDescr from "./../../assets/aboutDesc.png";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import FaqForm from "../../components/FaqForm/FaqForm";
import { useTranslation } from "react-i18next";

const About: FC = () => {
  const { t } = useTranslation();
  const text: any = {
    about: t("About.about"),
    gendir: t("About.gendir"),
    team: t("About.team"),
    mission: t("About.mission"),
  };

  const dispatch = useAppDispatch();
  const about = useAbout();
  useEffect(() => {
    dispatch(fetchAbout() as any);
  }, []);

  const [teamArr, setTeamArr] = useState<any>();

  useEffect(() => {
    setTeamArr(about?.employers?.slice(0, 4));
  }, [about]);

  const [none, setNone] = useState<any>({});

  const [tab, setTab] = useState("about");

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [moreText, setMoreText] = useState(false);
  const [moreText2, setMoreText2] = useState(false);
  const [moreText3, setMoreText3] = useState(false);

  useEffect(() => {
    setMoreText(false);
  }, [tab]);
  return (
    <div className="aboutWrapper">
      <Container>
        <div className="aboutContentWrapper">
          <p className="aboutTitle">{text[tab]}</p>
          <div className="aboutTabsWrapper">
            <div
              className={`aboutTab ${tab === "about" && "aboutTabActive"}`}
              onClick={() => setTab("about")}
            >
              {t("About.about")}
            </div>
            <div
              className={`aboutTab ${tab === "gendir" && "aboutTabActive"}`}
              onClick={() => setTab("gendir")}
            >
              {t("About.gendir")}
            </div>
            <div
              className={`aboutTab ${tab === "team" && "aboutTabActive"}`}
              onClick={() => setTab("team")}
            >
              {t("About.team")}
            </div>
            <div
              className={`aboutTab ${tab === "mission" && "aboutTabActive"}`}
              onClick={() => setTab("mission")}
            >
              {t("About.mission")}
            </div>
          </div>
          {tab === "about" && (
            <div className="aboutBlock">
              <div className="aboutBlockDesc">
                <p>
                  {moreText
                    ? about?.main_info?.description
                    : about?.main_info?.description.slice(0, 467) + "..."}
                </p>
                {!moreText && (
                  <p
                    onClick={() => setMoreText(true)}
                    className={"readMoreAbout"}
                  >
                    {t("About.readMore")}
                  </p>
                )}
                {!isMobile && <img alt="team" src={aboutDescr} />}
              </div>

              <img src={aboutImg} alt="about" />
            </div>
          )}
          {tab === "team" && (
            <div>
              <div className="aboutTeamWrapper">
                <div style={{ width: "50%" }}>
                  <p>
                    {moreText2
                      ? about?.our_team?.text
                      : about?.our_team?.text.slice(0, 400) + "..."}
                  </p>
                  {!moreText && (
                    <p
                      onClick={() => setMoreText2(true)}
                      className={"readMoreAbout"}
                    >
                      {t("About.readMore")}
                    </p>
                  )}
                </div>
                <div className="aboutTeamBlock">
                  <img alt="team" src={teamImg} />
                  <p className="aboutTeamBlockTitle">Перизат Токтоматова</p>
                  <p className="aboutTeamBlockDesc">{t("About.descr")}</p>
                </div>
              </div>
              <div className="aboutEmployeeBlock">
                {teamArr?.map((item: any) => (
                  <div className="aboutEmployeeItem">
                    <img
                      src={"https://suveniry-mmt.kz/" + item.picture}
                      alt={item.position}
                    />
                    <p className="aboutEmployeeItemTitle">{item.name}</p>
                    <p className="aboutEmployeeItemDesc">{item.position}</p>
                  </div>
                ))}
                {about?.employers?.length > 4 && (
                  <div
                    className="aboutMore"
                    style={none}
                    onClick={() => {
                      setTeamArr(about?.employers);
                      setNone({ display: "none" });
                    }}
                  >
                    {t("About.lookMore")}
                  </div>
                )}
              </div>
            </div>
          )}
          {tab === "gendir" && (
            <div className="aboutBlock gendirBlock">
              <div className="aboutBlockDesc genDirdesc">
                <div>
                  <p>
                    {moreText
                      ? about?.director?.personal_information
                      : about?.director?.personal_information.slice(0, 467) +
                        "..."}
                  </p>
                  {!moreText && (
                    <p
                      onClick={() => setMoreText(true)}
                      className={"readMoreAbout"}
                    >
                      {t("About.readMore")}
                    </p>
                  )}
                </div>
                {!isMobile && <img alt="team" src={aboutDescr} />}
              </div>
              <div className="aboutTeamBlock gendirPhoto">
                <img
                  src="https://suveniry-mmt.kz/static/media/team.418734ce15757fca9c6f.png"
                  alt="about"
                />
                <p className="aboutTeamBlockTitle">{about?.director?.name}</p>
                <p className="aboutTeamBlockDesc">{t("About.descr")}</p>
              </div>
            </div>
          )}
          {tab === "mission" && (
            <div className="aboutBlock missionBlock">
              <div className="aboutBlockDesc">
                <p>
                  {moreText3
                    ? about?.our_mission?.text
                    : about?.our_mission?.text.slice(0, 400) + "..."}
                </p>
                {!moreText && (
                  <p
                    onClick={() => setMoreText3(true)}
                    className={"readMoreAbout"}
                  >
                    {t("About.readMore")}
                  </p>
                )}
                {!isMobile && (
                  <img alt="team" src={about?.our_mission?.image1} />
                )}
              </div>

              <img
                className={"missionImg"}
                src={about?.our_mission?.image2}
                alt="about"
              />
            </div>
          )}
        </div>
        <div className={"about_faq"}>
          <FaqForm />
        </div>
      </Container>
    </div>
  );
};

export default About;
