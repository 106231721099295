import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import './Header.scss'
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { API_V2, currentCurrency, currentLang } from "../../constants/api";
import { useTranslation } from "react-i18next";
import CurrencySelect from "./currencyDropdown/CurrencyDropdown";
import LangDropdown from "./langDropdown/LangDropdown";
import Modal from "./modal/modal";
interface IHeaderSecondary{
    setOpen: (open: boolean) => void,
    hadnleNavigate: (link: string) => void,
}

const HeaderSecondary:FC<IHeaderSecondary> = (props) => {

  const token = localStorage.getItem("token");

  const { setOpen, hadnleNavigate } = props

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const {t} = useTranslation()


  const [subcategories, setSubcategories] = useState<any>([]);
  const [mobSub, setMobSub] = useState([])
  const [isSub, setIsSub] = useState(false)

  const [categories, setCategories] = useState<any>([])

  useEffect(() => {
    // dispatch(fetchCategories() as any);
  (async () => {
    const res = await API_V2.get('/products/category/')
    setCategories(res.data)
    console.log(res.data, 'res')

  })()
  }, []);

  // const categories = useCategoryProducts();


  const handleCategoryClick = async (link: string, sub?: any) => {
   if(!isMobile){
    navigate(link)
    if (location.pathname === "/catalog") {
      setTimeout(() => window.location.reload(), 100);
    }
    setOpen(false)
  }
    else{ 
      const res = await sub
      console.log(await sub, 'sub')
      setMobSub(res)
      setIsSub(true)
    }
  } 

  const isMobile = useMediaQuery('(max-width: 768px)')

  const handleNavigate = (link: string) => {
    hadnleNavigate(link);
    setOpen(false);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }



      const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  }
  const closeModal = () => setIsModalOpen(false);


      const [isLangModalOpen, setIsLangModalOpen] = useState(false);

  const openLangModal = () => {
    setIsLangModalOpen(true);
  }
  const closeLangModal = () => setIsLangModalOpen(false);

    return (
        <div 
            className="headerSecondaryWrapper"
            onMouseLeave={() => setOpen(false)}
        >
          <div className="headerSecondaryCategory">
            {!isSub ? <>{categories.length > 0 && categories?.map((category: any) => (
              <>
                <div 
                  className="headerSecondaryCategoryItem" 
                  key={category.name}
                  onClick={() => handleCategoryClick(`/catalog?categoryId=${category.slug}`, 
                    (async () => {
                      const res = await API_V2.get(`/products/category/${category.slug}`)
                      return res.data
                  })()
                )}
                  onMouseOver={async () =>{
                      const res = await API_V2.get(`/products/category/${category.slug}`)
                      setSubcategories(res.data)
                  }}
                  >
                    {category.name}
                </div>
                </>
            ))}</> : <>           
            {isMobile 
            && 
            <div>
              <p
                  className="headerSecondaryCategoryItem" 
                  onClick={() => setIsSub(false)}
              >Назад</p>
              {mobSub?.map((item: any) => (
                <>
                <div 
                onClick={() => {
                      navigate(`/catalog?categoryId=${item?.slug}`)
                      if (location.pathname === "/catalog") {
                        setTimeout(() => window.location.reload(), 100);
                      }
                      setOpen(false)
                      }}
                  className="headerSecondaryCategoryItem" 
                >{item?.name}</div>
                </>
              ))}
            </div>
            }</>}
            

 

          </div>
          {
            !isMobile
            &&
          <>
          <div className="headerDivider"></div>
          <div className="headerSecondarSubcategory cursor-pointer">
            {subcategories?.length > 0 &&
                subcategories?.map((item: any) => (
                  <div 
                    key={item?.id}
                  >
                    <div 
                      className="headerSecondarSubcategoryItem"
                      onClick={() => handleCategoryClick(`/catalog?categoryId=${item.slug}`)}
                    >
                        {item?.name}
                    </div>
                    {item?.subcategories?.length > 0 && item?.subcategories?.map((item: any) => (
                      <div 
                        className="headerSecondarSubSubcategoryItem"
                        onClick={() => handleCategoryClick(`/catalog?categoryId=${item.slug}`)}
                      >
                        {item?.name}
                    </div>
                    ))}
                  </div>
                ))}
          </div>
          </>
          }

          {
            isMobile
            &&
            <div>
                <Modal isOpen={isModalOpen} onClose={closeModal} title="Валюта">
                    <CurrencySelect />
                </Modal>
                <Modal isOpen={isLangModalOpen} onClose={closeLangModal} title="Язык">
                    <LangDropdown defaultOption={currentLang} />
                </Modal>
              <div 
                    className="headerNavigation"
                >
                    {!token ? <div onClick={() => handleNavigate('/signup')}>{t('Header.auth')}</div> :<div onClick={() => handleNavigate('/profile/')}>{t('Header.profile')}</div>}
                    {!token ? <div onClick={() => handleNavigate('/signin')}>{t('Header.login')}</div>: <></>}
                    <div onClick={() => handleNavigate('/about')}>{t('Header.company')}</div>
                    <div onClick={() => handleNavigate('/portfolio')}>{t('Header.portfolio')}</div>
                    <div onClick={() => handleNavigate('/')}><a href="#faq">{t('Header.faq')}</a></div>
                    <div onClick={() => handleNavigate('/contacts')}>{t('Header.contacts')}</div>
                    <a href="tel:+996555883500">{t('Header.call')}</a>
                    <div onClick={() => handleNavigate('/blog')}>{t('Header.blog')}</div>
                    <div onClick={() => handleNavigate('/lookbook')}>{t('Header.lookbook')}</div>
                       <div className="flex gap-[10px]"> 
                        <span 
                          onClick={openModal}
                          className='text-[12px] text-[#fff] bg-[#222222] py-[4px] px-[9px] rounded-[10px] cursor-pointer'>{currentCurrency.toUpperCase()}</span>
                        <span onClick={openLangModal} className='text-[12px] text-[#fff] bg-[#222222] py-[4px] px-[9px] rounded-[10px] cursor-pointer'>{currentLang.toUpperCase()}</span>
                        </div>
                </div>
            </div>
          }
        </div>
        )
}

export default HeaderSecondary