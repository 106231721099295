import { createReducer } from "@reduxjs/toolkit";
import {
  fetchAboutSuccess,
  fetchBlogNewsSuccess,
  fetchHomeSuccess,
  fetchPortfolioSuccess,
  fetchSelectedBlogSuccess,
  getBannerSuccess,
  getBrandsSuccess,
  getCatalogSuccess,
  getFaqSuccess,
  getHomePorfolioSuccess,
  getReviewSuccess
} from "./actions";
import { IVariaState } from "./interfaces/data.interface";

export const initialState: IVariaState = {
  blog: null,
  about: null,
  portfolio: null,
  home: null,
  selectedBlog: null,

  banner: null,
  catalog: null,
  homePortfolio: null,
  review: null,
  brands: null,
  faq: null,
};

export default createReducer<IVariaState>(initialState, (builder) =>
  builder
    .addCase(
      fetchBlogNewsSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        blog: payload,
      })
    )
    .addCase(
      fetchSelectedBlogSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        selectedBlog: payload,
      })
    )
    .addCase(
      fetchAboutSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        about: payload,
      })
    )
    .addCase(
      fetchPortfolioSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        portfolio: payload,
      })
    )
    .addCase(
      fetchHomeSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        home: payload,
      })
    )

    // ====================
    .addCase(
      getBannerSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        banner: payload,
      }))
    .addCase(
      getReviewSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        review: payload,
      }))
    .addCase(
      getCatalogSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        catalog: payload,
      }))
    .addCase(
      getBrandsSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        brands: payload,
      }))
    .addCase(
      getHomePorfolioSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        homePortfolio: payload,
      }))
    .addCase(
      getFaqSuccess,
      (state, { payload }): IVariaState => ({
        ...state,
        faq: payload,
      }))
      
);
