import { FC, useEffect, useState } from "react";
import Container from "../../components/footer/Container";
import look from '../../assets/look/look.jpg'
import look1 from '../../assets/look/look1.jpg'
import look2 from '../../assets/look/look133.png'
import look3 from '../../assets/look/look134.png'
import look4 from '../../assets/look/look135.png'
import look5 from '../../assets/look/look136.png'
import look6 from '../../assets/look/look137.png'
import look7 from '../../assets/look/look138.png'
import look8 from '../../assets/look/look139.png'
import look9 from '../../assets/look/look140.png'
import look10 from '../../assets/look/look141.png'
import look11 from '../../assets/look/look142.png'
import classes from './Lookbook.module.scss'
import FaqForm from "../../components/FaqForm/FaqForm";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { AxiosResponse } from "axios";
import { PUBLIC_API } from "../../constants/api";
import { useTranslation } from "react-i18next";

const Lookbook:FC = () => {
    const isMobile = useMediaQuery('(max-width: 768px)')

    const {t} = useTranslation()

const getLookbookContent = async(): Promise<AxiosResponse<any> | null> => {
  try {
      const res: AxiosResponse<any> = await PUBLIC_API.get("/lookbook/");
      return res.data;
  }catch{
      return null
  }}

  const [dataLook, setDataLook] = useState<any>()

  useEffect(() => {
    (async () => {
        const data = await getLookbookContent();
        console.log(data)
        setDataLook(data)
    })()
  }, [])

    return(
        <Container className={classes.container}>
            <h1 className={classes.headtitle}>{t('Lookbook.title')}</h1>
            <div className={classes.lookbook}>
                <div className={classes.lookbook1}>
                    <p className={classes.text}>
                        {dataLook?.text1} 
                    </p>
                    <img className={classes.big} src={dataLook?.image1 || look} alt={""}/>
                    <img className={classes.small} src={dataLook?.image2 || look1} alt={""}/>
                </div>
                {
                    !isMobile
                    &&
                    <div className={classes.lookbok2}>
                        <img className={classes.big} src={dataLook?.image3 || look2} alt={""}/>
                        <img className={classes.small} src={dataLook?.image4 || look3} alt={""}/>
                    </div>
                }
                <div className={`${classes.lookbook4} ${classes.lookbook1}`}>
                    <p className={classes.text}>
                        {dataLook?.text2} 
                    </p>
                    <img className={classes.small} src={dataLook?.image5 || look5} alt={""}/>
                    <img className={classes.big} src={dataLook?.image6 || look4} alt={""}/>
                </div>
                {
                    !isMobile
                    &&
                    <div className={classes.lookbok5}>
                        <img className={classes.big} src={dataLook?.image7 || look5} alt={""}/>
                        <img src={dataLook?.image8 || look6} alt={""}/>
                    </div>
                }
                <div className={classes.lookbook1}>
                    <p className={classes.text}>
                        {dataLook?.text3} 
                    </p>
                    <div className={classes.smallestImg}>
                        <img className={classes.small} src={dataLook?.image9 || look7} alt={""}/>
                        <img className={classes.small} src={dataLook?.image10 || look8} alt={""}/>
                    </div>
                </div>
                <div className={classes.lookbook1}>
                    <p className={classes.text}>
                    
                    </p>
                    <div className={classes.smallestImg}>
                        <img className={classes.big} src={dataLook?.image11 || look9} alt={""}/>
                        <img className={classes.small} src={dataLook?.image12 || look10} alt={""}/>
                    </div>

                </div>
            </div>
            <div className={classes.faqForm}>
                <FaqForm/>
            </div>
        </Container>
    );
};

export default Lookbook;