import React, { useEffect, useState } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
import OrderHistory from "./OrderHistory";
import ProfileComponent from "./ProfileComponent";
import './Profile.css'




const Profile = () => {
  const [isProfile, setIsProfile] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const search = new URLSearchParams().get("page");
    if (search === "history") {
      setIsProfile(false);
      return;
    }
    setIsProfile(true);
  }, [window.location.search]);

  return (
    <>
      <div className="px-2  md:w-auto py-5">
        <div className="flex justify-around px-8 md:flex-row profileBlock">
          <div className="w-full">
            <h1 className="font-bold text-[36px] text-2xl ">Личный кабинет</h1>
            <p style={{marginTop: '16px', color: ''}} className="text-[14px] font-[300]">Мой профиль</p>
          </div>
          <div className="flex gap-5 md:justify-end justify-between w-full h-11">
            <button
              onClick={() => navigate("/profile/order-history")}
              className={`${
                !isProfile ? "bg-black text-white " : "bg-white text-black"
              } border-gray-900 border-2 !focus:outline-none h-auto flex md:flex rounded-full ... md:w-auto w-6/12 px-3 items-center py-2 gap-2 text-xs md:text-lg flex-row`}
            >
              История заказов
            </button>

            {/*  */}
            <button
              onClick={() => navigate("/profile")}
              className={`${
                isProfile ? "bg-black text-white " : "bg-white text-black"
              } border-gray-900 border-2 h-auto  flex md:flex rounded-full ... md:w-auto w-6/12 px-3 items-center py-2 gap-2 md:text-lg text-xs`}
            >
              <div className=" ">
              </div>
              Мой профиль
            </button>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<ProfileComponent />} />
          <Route path="/order-history" element={<OrderHistory />} />
        </Routes>
      </div>
    </>
  );
};

export default Profile;
