import { FC, useState } from 'react';
import Container from '../../footer/Container';
import logo from '../../../assets/img/logo.svg'
import { Link } from 'react-router-dom';
import basket from '../../../assets/icons/Basket.svg'
import phone from '../../../assets/icons/Phone.svg'
import search from '../../../assets/icons/Search.svg'
import user from '../../../assets/icons/User.svg'
import HeaderSecondary from "../HederSecondary";
import LangDropdown from '../langDropdown/LangDropdown';
import { currentLang, currentCurrency } from '../../../constants/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from '../modal/modal';
import CurrencySelect from '../currencyDropdown/CurrencyDropdown';

interface HeaderDesktopProps {
    hadnleNavigate: (lint: string) => void,
    setSecondaryHeaderOpen: any,
    token: string | null,
    isSearchOpen: boolean,
    setSearchContent: any,
    searchContent: any,
    handleSubmit: (e: any) => void,
    setSearchOpen: any,
    secondaryHeaderOpen: boolean,
}

const HeaderDesktop: FC<HeaderDesktopProps> = (props) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {secondaryHeaderOpen, handleSubmit, hadnleNavigate, setSecondaryHeaderOpen, token, isSearchOpen, setSearchContent, searchContent} = props;


      const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setSecondaryHeaderOpen(false)
  }
  const closeModal = () => setIsModalOpen(false);


      const [isLangModalOpen, setIsLangModalOpen] = useState(false);

  const openLangModal = () => {
    setIsLangModalOpen(true);
    setSecondaryHeaderOpen(false)
  }
  const closeLangModal = () => setIsLangModalOpen(false);

    return (
        <>
        <div className="headerHolder"></div>
        <div className="headerCombine">

            <div>
            <Container>
                <div className='flex justify-between mt-[10px]'>
                    <div className='flex items-center gap-[10px]'>
                        <Modal isOpen={isModalOpen} onClose={closeModal} title="Валюта">
                            <CurrencySelect />
                        </Modal>
                        <Modal isOpen={isLangModalOpen} onClose={closeLangModal} title="Язык">
                            <LangDropdown defaultOption={currentLang} />
                        </Modal>
                        <span 
                            onClick={openModal}
                         className='text-[12px] text-[#fff] bg-[#222222] py-[4px] px-[9px] rounded-[10px] cursor-pointer'>{currentCurrency.toUpperCase()}</span>
                        <p onClick={() => hadnleNavigate('/about')} className='text-[12px] text-[#fff] cursor-pointer'>{t('About.about')}</p>
                    </div>
                    <div className='flex items-center gap-[20px]'>
                        <p onClick={() => hadnleNavigate('/portfolio')} className='text-[12px] text-[#fff] cursor-pointer'>{t('Header.portfolio')}</p>
                        <p onClick={() => hadnleNavigate('/blog')} className='text-[12px] text-[#fff] cursor-pointer'>{t('Header.blog')}</p>
                        <p onClick={() => hadnleNavigate('/')} className='text-[12px] text-[#fff] cursor-pointer'><a href="#faq">{t('Header.faq')}</a></p>
                        <p onClick={() => hadnleNavigate('/contacts')} className='text-[12px] text-[#fff] cursor-pointer'>{t('Header.contacts')}</p>
                        <p  onClick={() => hadnleNavigate('/lookbook')} className='text-[12px] text-[#fff] cursor-pointer'>{t('Header.lookbook')}</p>
                        <span onClick={openLangModal} className='text-[12px] text-[#fff] bg-[#222222] py-[4px] px-[9px] rounded-[10px] cursor-pointer'>{currentLang.toUpperCase()}</span>

                    </div>
                </div>
                <div className="headerWrapper flex gap-[20px]">
                <div className="headerLogo" 
                onClick={() => hadnleNavigate('/')}
                onMouseEnter={() => setSecondaryHeaderOpen(false)}

                >
                    <img style={{cursor: 'pointer', width: 75, maxWidth: '75px'}} src={logo} alt="ммт" />
                </div>
                <div 
                className="headerCatalog"
                onMouseEnter={() => setSecondaryHeaderOpen(!secondaryHeaderOpen)}
                >
                    <div className="headerMenu">
                        <div></div>
                        <div></div>
                    </div>
                    <div onClick={() => hadnleNavigate('/catalog')}>{t('Header.catalog')}</div>
                </div>
                {<form className="p-0 flex relative max-w-[70%] w-full " onSubmit={(e) => {
                    console.log('2222')
                    handleSubmit(e)
                    navigate(`/catalog?search=${searchContent}`)
                    setTimeout(() => window.location.reload(), 100)
                    }}>
                            <input
                                onChange={(e: any) =>
                                setSearchContent(e.target.value)
                                }
                                className="relative z-[1] block w-full border-none rounded-full py-[13px] pl-9 !pr-0 shadow-sm focus:outline-none  sm:text-sm text-[#000]"
                                placeholder="Поиск..."
                                type="text"
                                name="search"
                            />
                            <div className='w-[50px]'></div>
                            <div className='absolute  w-[50px] right-[8px]  '>
                            <Link
                                className="  focus:outline-none "
                                to={`/catalog?search=${searchContent}`}>
                                <div
                                className='absolute bg-[#222222] w-full flex items-center justify-center  h-[46px] rounded-br-full rounded-tr-full  cursor-pointer'>
                                    <img
                                    onClick={() =>{
                                    setTimeout(() => window.location.reload(), 100)
                                        }}
                                        src={search}
                                        className='ml-[10px]'
                                        alt=""
                                    />
                                </div>
                            </Link>

                            </div>
                            
                    </form>}

                <div 
                    className="headerActions  flex items-center"
                    onMouseEnter={() => setSecondaryHeaderOpen(false)}
                >
                    <div className='flex justify-between w-full gap-[20px]'>
                        {token && <img src={basket} onClick={() => hadnleNavigate('/cart')} alt="корзина"/>}
                        <a href="tel:+996555883500" className='w-[24px] h-[24px] flex items-center'><img src={phone} alt="телефон"/></a>
                        {token && <img src={user} onClick={() => hadnleNavigate('/profile')} alt="пользователь"/>}
                    </div>
                    {!token &&     
                        <div className="flex gap-4 justify-between items-center w-60 ml-4">
                            <Link to="/signup">
                                <button className="text-white cursor-pointer focus:outline-none flex items-center text-center justify-center">
                                {t('Header.auth')}
                                </button>
                            </Link>

                            <Link to="/signin">
                                <button
                                className=" flex justify-center rounded-full w-auto text-white px-10 items-center py-[13px] bg-[#1F1F1F] transition .4s hover:scale-105"
                                >
                                {t('Header.login')}
                                
                                </button>
                            </Link>
                        </div>
                    }
                </div>
                </div>  
            </Container>
            </div>
            {secondaryHeaderOpen && <HeaderSecondary hadnleNavigate={hadnleNavigate} setOpen={setSecondaryHeaderOpen}/>}
        </div>
        </>
    );
};

export default HeaderDesktop;