import { FC, useEffect, useState } from "react";
import { getCatalogCategoryProducts, getFiltersProducts } from "../../store/catalog";
import { useAppDispatch } from "../../store";
import { useLocation } from "react-router-dom";
import { useCatalogProducts, useFilters } from "../../store/catalog/hooks";
import Container from "../../components/footer/Container";
import CatalogCard from "../../components/CatalogCard";
import InfiniteScroll from "react-infinite-scroll-component";
import CatalogFilters from "../../components/CatalogFilters";
import './Catalog.scss'
import { useMediaQuery } from "../../hooks/useMediaQuery";
import filterImage from '../../assets/icons/Filter.svg';
import { PUBLIC_API } from "../../constants/api";
import { useTranslation } from "react-i18next";
import { currentCurrency } from "../../constants/api";


const Catalog:FC = () => {

    const {t} = useTranslation()

    const { search } = useLocation();
    const categoryId = new URLSearchParams(search).get("categoryId");
    const catalog_pk = new URLSearchParams(search).get("catalog_pk");
    const searchData = new URLSearchParams(search).get("search");

    const dispatch = useAppDispatch();

    const [selectedFilters, setSelectedFilters] = useState<{
        colors: any[];
        materials: any[];
        custom_types: any[];
        types: any[];
        category: any;
        search: any;
        catalog_pk: any;
        currency: any
    }>({
        colors: [],
        materials: [],
        custom_types: [],
        types: [],
        category: categoryId,
        search: searchData,
        catalog_pk: catalog_pk,
        currency: currentCurrency
    });
    useEffect(() => {
        dispatch(getCatalogCategoryProducts({offset: 0,  ...selectedFilters}) as any)
    }, [categoryId, searchData, catalog_pk]);


    const catalogProducts = useCatalogProducts();

    const URL = new URLSearchParams(catalogProducts?.next?.toString()?.split('?')[ 1 ])
    const limit: number = Number(URL.get('limit')) || 12;
    const offset: number = Number(URL.get('offset')) || 0;


    const [catalogProductsData, setCatalogProductsData] = useState<any>([])

    useEffect(() => {
      if(JSON.stringify(catalogProducts?.results) && !JSON.stringify(catalogProductsData).includes(JSON.stringify(catalogProducts?.results))){
        setCatalogProductsData((prev: any) => [...prev, ...catalogProducts?.results])
      } 
    }, [catalogProducts]);

    const fetchData = async (off: any = offset, lim: any = limit, params: any = selectedFilters) => {
        await dispatch(getCatalogCategoryProducts( {
            offset: off,
            limit: lim || undefined, 
            ...params
        }) as any)
    }

    const [categoryInfo, setCategoryInfo] = useState<any>(null)

    useEffect(() => {
       (async () => {
            await dispatch(getFiltersProducts() as any)
            const categoryData = await PUBLIC_API.get(`products/category/${categoryId}`)
            setCategoryInfo(categoryData.data)
            console.log(categoryData.data, 'categoryData')
        })() 
    }, [])

    const filters = useFilters();

    const isMobile = useMediaQuery('(max-width: 768px)')
    const [openFilters, setOpenFiters] = useState(false);

    const handleOpenFilters = () => setOpenFiters(true);

    const [loading, setLoading] = useState(
        <div className="flex gap-[8px] ml-[8px] flex-wrap">
            <div className="animate-pulse md:w-[360px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
            <div className="animate-pulse md:w-[360px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
            <div className="animate-pulse md:w-[360px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
            <div className="animate-pulse md:w-[360px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
            <div className="animate-pulse md:w-[360px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
            <div className="animate-pulse md:w-[360px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
        </div>
    )

    useEffect(() => {
        if(catalogProducts?.count === 0){
            setLoading(<div className={'notFound_catalog'}>Ничего не найдено</div>)
        }
    }, [catalogProductsData, catalogProducts])


    return(
        <Container id={'filters_container_page'}>
            <div>
                <div className={'catalog_top_block'}>
                <div>
                    <p className="catalogTitle">
                        {t('Catalog.title')}
                    </p>
                    {categoryInfo ? 
                    <div className="mt-[20px]">
                        <p className="text-[24px] font-bold">{categoryInfo.name}</p>
                        <p className="font-[300] mt-[10px]">{categoryInfo.description}</p>
                    </div>
                    :<></>}
                </div>

                {
                    isMobile
                    &&
                    <p onClick={handleOpenFilters} className={'catalog_filters_mobile'}>
                        {t('Catalog.filters')}
                        <img src={filterImage} alt=""/>
                    </p>
                }
                </div>
                <div className="catalogWrapper">
                    <CatalogFilters 
                        handleClose={() => setOpenFiters(false)}
                        selectedFilters={selectedFilters} 
                        setSelectedFilters={setSelectedFilters} 
                        setCatalogProductsData={setCatalogProductsData} 
                        filters={filters}  
                        fetchData={fetchData}
                        searchData={searchData}
                        openFilters={openFilters}
                        category={categoryId}
                    />
                    {
                        catalogProductsData?.length <= 0
                        ?
                        loading
                        :
                    <div style={{width: '100%'}}>
                        
                        <InfiniteScroll
                            className="catalogCardsScroll"
                            dataLength={catalogProductsData?.length || 0}
                            hasMore={Boolean(catalogProducts?.next)}
                            loader={
                                    <div className="flex gap-[8px] ml-[8px] flex-wrap w-full">
                                        <div className="animate-pulse md:w-[355px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
                                        <div className="animate-pulse md:w-[355px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
                                        <div className="animate-pulse md:w-[355px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
                                        <div className="animate-pulse md:w-[355px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
                                        <div className="animate-pulse md:w-[355px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
                                        <div className="animate-pulse md:w-[355px] w-[160px] max-w-[375px] md:h-[483px] h-[195px] mb-10 rounded-[20px] bg-gray-200 dark:bg-gray-700"></div>
                                    </div>
                            }
                            next={fetchData}
                        >
                            {catalogProductsData?.map((item: any, i: number) => (
                                <CatalogCard  
                                    title={item?.name}
                                    price={item?.[`discount_price_${currentCurrency}`]}
                                    img={item?.colors[0]?.images[0]?.small}
                                    num={item?.article}
                                    stock={item.total_stock}
                                    id={item.id}
                                    slug={item.slug}
                                    key={i} 
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
                    }
                </div>
            </div>
        </Container>
    );
};


export default Catalog;