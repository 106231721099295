import axios from "axios";

export const API_ADDRESS = "https://suveniry-mmt.kz/api/v1/";
export const API_ADDRESS_V2 = "https://suveniry-mmt.kz/api/v2/";
// export const API_ADDRESS = "http://195.38.164.82/api/v1/";

export const currentLang = localStorage.getItem('lang') || 'ru'
export const currentCurrency = localStorage.getItem('curr') || 'kzt'


const API = axios.create({
  baseURL: API_ADDRESS,
  headers: {
    "Accept-Language": currentLang,
  }
});

export const PUBLIC_API = axios.create({
  baseURL: API_ADDRESS,
  headers: {
    "Accept-Language": currentLang,
  }
});

export const API_V2 = axios.create({
  baseURL: API_ADDRESS_V2,
  headers: {
    "Accept-Language": currentLang,
  }
})

API.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Token ${localStorage.getItem("token")}`;
  return config;
});

// API.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   async (err) => {
//     const originalRequest = err.config;
//     if (err.response.status === 401 && !err.config._isRetry) {
//       originalRequest._isRetry = true;
//       try {
//         const response = await axios.post(
//           `${API_ADDRESS}/api/v1/users/refresh/`,
//           {
//             refresh: localStorage.getItem("refresh"),
//           }
//         );
//         localStorage.setItem("token", response.data.access);
//         return API.request(originalRequest);
//       } catch (e) {
//         return Promise.reject(e);
//       }
//     } else {
//       return Promise.reject(err);
//     }
//   }
// );

export default API;
