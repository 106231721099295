import { FC } from "react";
import './WhyMMT.scss'
import one from './6.svg'
import two from './7.svg'
import three from './Depth.svg'
import four from './Portal.svg'
import five from './Group 89.svg'
import six from './Smile.svg'
import { useTranslation } from "react-i18next";

interface IWhyMMTItemProps{
    icon: string
    title: string
    description: string
}

const WhyMMTItem:FC<IWhyMMTItemProps> = (props) => {

    const {icon, title, description} = props

    return (
        <div className="whyMMTItem">
            <img src={icon} alt="icon"/>
            <p className="whyMMTTitle">{title}</p>
            <p className="whyMMTDescr">{description}</p>
        </div>
    )
}

const WhyMMT:FC = () => {
const {t} = useTranslation()

    const whyArr = [
        {
            icon: five,
            title:  t('Why.contentTitle1'),
            description: t('Why.contentDescr1'),
        },
        {
            icon: two,
            title: t('Why.contentTitle2'),
            description:  t('Why.contentDescr2'),
        },
        {
            icon: one,
            title: t('Why.contentTitle3'),
            description: t('Why.contentDescr3'),
        },
        {
            icon: four,
            title: t('Why.contentTitle4'),
            description:  t('Why.contentDescr4'),
        },
        {
            icon: six,
            title: t('Why.contentTitle5'),
            description:  t('Why.contentDescr5'),
        },
        {
            icon: three,
            title: t('Why.contentTitle6'),
            description:  t('Why.contentDescr6'),
        }
    ]

    return(
        <div className="whyWrapper">
            <div className="whyTitleWrapper">
                <p className="whyTitle">{t('Why.title')}</p>
            </div>
            <div className="whyMMTBlock">
                {whyArr.map((item) => (
                    <WhyMMTItem 
                        key={item.title}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                    />
                ))}
            </div>
        </div>
    )
}


export default WhyMMT;