import { createAction } from "@reduxjs/toolkit";
import {
  IAbout,
  IBlogs,
  IHomeData,
  IPortfolio,
} from "./interfaces/data.interface";

export const fetchBlogNewsSuccess = createAction<IBlogs>(
  "blog/fetchBlogNewsSuccess"
);

export const fetchSelectedBlogSuccess = createAction<IBlogs>(
  "blog/fetchSelectedBlogSuccess"
);

export const fetchAboutSuccess = createAction<IAbout>(
  "about/fetchAboutSuccess"
);
export const fetchPortfolioSuccess = createAction<IPortfolio>(
  "portfolio/fetchPortfolioSuccess"
);
export const fetchHomeSuccess = createAction<IHomeData>(
  "home/fetchHomeSuccess"
);

//=====================================

export const getBannerSuccess = createAction<any>( //i know, but its to late to change everything
  "about/getBannerSuccess"
);

export const getCatalogSuccess = createAction<any>( //i know, but its to late to change everything
  "about/getCatalogSuccess"
);

export const getHomePorfolioSuccess = createAction<any>( //i know, but its to late to change everything
  "about/getPorfolioSuccess"
);

export const getReviewSuccess = createAction<any>( //i know, but its to late to change everything
  "about/getReviewSuccess"
);

export const getBrandsSuccess = createAction<any>( //i know, but its to late to change everything
  "about/getBrandsSuccess"
);

export const getFaqSuccess = createAction<any>( //i know, but its to late to change everything
  "about/getFaqSuccess"
);