import { FC, ReactNode, useEffect } from "react";
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { useAppDispatch } from "../../../store";
import { getFaq } from "../../../store/varia";
import { useFaq } from "../../../store/varia/hooks";
import './Qestions.css'
import icon from './Frame.svg'
import { useTranslation } from "react-i18next";


const Qestions:FC = () => {

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;


  const dispatch = useAppDispatch();

  useEffect(() => {
      dispatch(getFaq() as any);
  }, [dispatch]);

  const faq = useFaq()

  const addZero = (num: number) => {
    if(num < 10){
    return `0${num}`
      }else{
        return num
      }
  }

  const {t} = useTranslation()


const items: CollapseProps['items'] = faq?.map((item: any, i: number) => (
    {
      key: i,
      label: <div><p>{item.question}</p><p>{t('Question.answer')}</p></div>,
      children: <p>{item.answer}</p>,
      extra: <span>{addZero(++i)}</span>
    }
  )) 


   return(
        <div id="faq">
            <p className="qestionTitle">{t('Question.title')}</p>
            <div className="faqCollapse">   
              <Collapse items={items} expandIconPosition={'end'} expandIcon={() => <div className="faqIcon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame">
<path id="Vector" d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V5Z" fill="white"/>
<path id="Vector_2" d="M19 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11Z" fill="white"/>
</g>
</svg></div>}/>
            </div>
        </div>)
}

export default Qestions;