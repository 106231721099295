import { FC } from 'react';
import classes from './FaqForm.module.scss';
import { Button, Checkbox, Form, Input } from 'antd';
import { PUBLIC_API } from '../../constants/api';
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

const FaqForm: FC<{showTitle?: boolean, showQuestion?: boolean}> = (props) => {
    const {showTitle=true, showQuestion=false} = props;

    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        const newValues = {...values}        
        delete newValues.politika
        const backData = {
            ...newValues,
        }
        try {
            const res = await PUBLIC_API.post('partners/application-call/', backData)
            if (res.data.status === 'CREATED'){
                toast.success(t('VideoConsulting.success'))
            }
        } catch (error) {
            toast.error(t('VideoConsulting.error'))
            return;
        }
    }
    const onFinishFailed = (values: any) => {
        toast.error(t('VideoConsulting.fillFields'))
    }
    const isMobile = useMediaQuery('(max-width: 768px)')

    const {t} = useTranslation()
    return (
        <>
        <Form
            form={form}
            onFinish={onFinish} 
            onFinishFailed={onFinishFailed} 
        >
            {
                showTitle
                &&
                <h1 className={classes.headTitle}>{t('Feedback.title')}Готовы ответить на любые вопросы — спрашивайте</h1>

            }
            <div className={classes.inputs}>
                <Form.Item rules={[{ required: true, message: t('VideoConsulting.enterName') }]} name={'name'}>
                    <Input className={classes.input} placeholder={t('VideoConsulting.name')}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: t('Feedback.enterPhone') }]} name={'phone_number'}>
                    <Input className={classes.input} placeholder={'+996'}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: t('VideoConsulting.enterEmail') }]} name={'email'}>
                    <Input className={classes.input} placeholder={'email'}/>
                </Form.Item>
                
                {
                !isMobile
                &&
                <Form.Item>

            <Button className={classes.submit} type="primary" htmlType="submit">
                   {t('Feedback.ask')}
                </Button>
            </Form.Item>
            }
            </div>
            {
                showQuestion
                &&
                <div>
                    <Form.Item name={'question'}>
                        <Input.TextArea className={classes.input} placeholder={'Вопрос'}></Input.TextArea>
                    </Form.Item>
                </div>
            }
            <div className={classes.bottom}>
            <Form.Item
                name={'politika'}
                valuePropName="checked"
                rules={[{ required: true, message: t('Feedback.agree') }]}
            >
                <Checkbox>
                    {t('Feedback.descr')}
                </Checkbox>
            </Form.Item>
            {
                isMobile
                &&
                <Form.Item>

            <Button className={classes.submit} type="primary" htmlType="submit">
                    {t('Feedback.ask')}
                </Button>
            </Form.Item>
            }
                
            </div>
            
        </Form>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </>
        
    );
};
export default FaqForm;