import { FC } from "react";
import classes from "./CatalogCard.module.scss";
import { useNavigate } from "react-router-dom";
import { currentCurrency } from "../../constants/api";

interface IProps {
  title: string;
  price: string;
  img: string;
  num: string;
  stock: string;
  id: string;
  slug: string;
}

const CatalogCard: FC<IProps> = (props) => {
  const navigate = useNavigate();

  const { title, price, img, num, stock, id, slug } = props;

  return (
    <div
      className={classes.catalogCardWrapper}
      onClick={() => navigate(`/product/${slug}`)}
    >
      <img className={classes.catalogCardImg} src={img} alt={title} />
      <div className={classes.catalogCardInfo}>
        <p className={classes.catalogCardTitle}>{title}</p>
        <p className={classes.catalogCardPrice}>
          {price}{" "}
          {currentCurrency === "kgs"
            ? "c"
            : currentCurrency === "rub"
            ? "₽"
            : "₸"}
        </p>
      </div>
      <div className={classes.catalogCardExtraInfo}>
        <div className={classes.catalogCardArticle}>арт. {num}</div>
      </div>
    </div>
  );
};

export default CatalogCard;
