import React, { FC, useEffect, useState } from "react";
import classes from './PageBlog.module.scss'
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import { PUBLIC_API } from "../../constants/api";
import Container from "../../components/footer/Container";

interface Props{
  img: string,
  title: string,
  created_at: string,
  brief_content: string,
  id: string,
  slug: string
}

const BlogItem:FC<Props> = (props) => {

  const {
    img,
    title,
    created_at,
    brief_content,
    id,
    slug
  } = props

  return (
      <div className={classes.blogItem}>
          <img src={img} alt={title}/>
          <p className={classes.blogItemTitle}>{title}</p>
          <p className={classes.created_at}>{new Date(created_at).toLocaleDateString()}</p>
          <div className={classes.bottom}>
            <p className={classes.brief}>{brief_content}</p>
            <Link className={classes.blog_item_link} to={`/blog/${slug}`} >Подробнее</Link>

          </div>
      </div>
  )
}

const getTabsBlog = async(): Promise<AxiosResponse<any> | null> => {
  try {
      const res: AxiosResponse<any> = await PUBLIC_API.get("/blog/tags");
      return res.data.data;
  }catch{
      return null
  }

}
const fetchBlog = async (params?: object): Promise<any | null> => {
  try {
    const res: AxiosResponse<any> = await PUBLIC_API.get("/blog/news", {params});      
    return res.data
  } catch (e) {
    console.log(e);
    return null;
  }
};
const LIMIT = 4


const Blog = () => {
  const [blog, setBlog] = useState<any>();

    const [tabData, setTabData] = useState<any>();

    useEffect(() => {
        (async () => {
            const blog = await fetchBlog({limit: LIMIT});
            setBlog(blog);
            const data = await getTabsBlog();
            setTabData(data)
        })()
    }, []);

    const handleSort = async (id: string) => {
        const blog = await fetchBlog({tag_id: id});
        setBlog(blog);
        setValueTab(id)
    }
    const handleMore = async () => {
        const url = new URL(blog?.next)
        const res = await fetchBlog({limit: LIMIT, offset: url.searchParams.get('offset')});
        const prev = {...blog}.results;
        const newData = {
            ...res,
            results: [
                ...prev,
                ...res?.results,
            ]
        }
        setBlog(newData)
        
       
    }
  const [valueTab, setValueTab] = useState<string | null>(null)
  return (
    <>
      <Container>
          <div className={'portfolio_wrapper'}>
              <p className={`porfolioBlockTitle ${classes.blogBlockTitle}`}>Блог</p>
              <div className={'portfolio_block_tab'}>
                  {tabData?.map((item: any) => (
                      <div 
                        onClick={()=>handleSort(item?.id)}
                        className={`portfolio_item_tab ${valueTab === item?.id ? classes.blog_item_tab_selected : ''}`} 
                        key={item?.id}
                      >
                        {item?.title}
                        </div>
                  ))}
              </div>
              <div className={classes.blogBlock}>
                  {blog && blog.results.map((item: any)=> (
                      <BlogItem 
                      id={item?.id} 
                      slug={item?.slug}
                      img={item?.picture}
                      title={item?.title}
                      brief_content={item?.brief_about}
                      created_at={item?.created_at}
                      />
                  ))}
                  
              </div>
              {
                  blog?.next 
                  &&
                  <div onClick={handleMore} className={'portfolio_getMore'}>
                      Посмотреть еще
                  </div>
              }
          </div>
      </Container>
  </>
  );
};

export default Blog;
