import { Dispatch } from "@reduxjs/toolkit";
import { PUBLIC_API } from "../../constants/api";
import {
  fetchAboutSuccess,
  fetchBlogNewsSuccess,
  fetchHomeSuccess,
  fetchPortfolioSuccess,
  fetchSelectedBlogSuccess,
  getBannerSuccess,
  getBrandsSuccess,
  getCatalogSuccess,
  getHomePorfolioSuccess,
  getFaqSuccess,
  getReviewSuccess
} from "./actions";

export const fetchBlogInfo = (search: string) => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get(`/blog/news?search=${search}`);
    dispatch(fetchBlogNewsSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const fetchSelectedBlog = (id: string) => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get(`/blog/news/${id}`);
    dispatch(fetchSelectedBlogSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const fetchAbout = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/about_us");
    dispatch(fetchAboutSuccess(res.data.data));
  } catch (e) {
    console.log(e);
  }
};
export const fetchPortfolio = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/portfolio");
    dispatch(fetchPortfolioSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const fetchHome = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/home");
    dispatch(fetchHomeSuccess(res.data.data));
  } catch (e) {
    console.log(e);
  }
};

//=======================================

export const getBanner = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/banner/");
    dispatch(getBannerSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const getCatalog = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/products/catalog/");
    dispatch(getCatalogSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const getPortfolio = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/portfolio/");
    dispatch(getHomePorfolioSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const getReview = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/rewiew/");
    dispatch(getReviewSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const getBrands = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/products/brands/");
    dispatch(getBrandsSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};

export const getFaq = () => async (dispatch: Dispatch) => {
  try {
    const res: any = await PUBLIC_API.get("/faq/");
    dispatch(getFaqSuccess(res.data));
  } catch (e) {
    console.log(e);
  }
};