import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import { FC, useEffect, useState } from "react";
import { PUBLIC_API } from "../../../constants/api";
import './Reviews.css'
import { useTranslation } from "react-i18next";
const Reviews:FC = () => {



const getReview = async(): Promise<AxiosResponse<any> | null> => {
  try {
      const res: AxiosResponse<any> = await PUBLIC_API.get("/products/video-reviews/");
      return res.data;
  }catch{
      return null
  }
}

const [review, setReview] = useState<any>()

useEffect(() => {
    (async () => {
    const data = await getReview()
    setReview(data)
    }
    )()
}, [])


const [rev, setRev] = useState<any>()

useEffect(() => {
    setRev(review?.slice(0, 2))
}, [review])

const {t} = useTranslation()

    return (
        <>
        {review && review?.length ?
            <div className="reviewWrapper">
                <div className="popTitleButtons">
                <p className="reviewTitle">{t('Reviews.title')}</p>
                    <div style={{marginTop: '10px'}} className="popButtons">
                        <div onClick={() => setRev(review?.slice(0, 2))}><LeftOutlined rev={undefined} /></div>
                        <div onClick={() => setRev(review?.slice(2, 4))}><RightOutlined rev={undefined} /></div>
                    </div>
                </div>
                <div className="reviewBlock">
                    {rev?.map((item: any) => (    
                        <div  className="reviewItem" key={item.video}>
                            <iframe className="reviewItemVideo"  title={'1'} src={'https://www.youtube.com/embed/' + item.video.replace('https://youtu.be/', '')}>
                                {t('Reviews.error')}
                            </iframe>
                        </div>
                    ))}
                </div>
            </div>
            : <></>
        }
        </>
    )
}

export default Reviews;