import { FC } from 'react';
import Container from '../../components/footer/Container';
import classes from './index.module.scss'
import womanImage from '../../assets/womanBruh.png';
import zoom from '../../assets/icons/Zoom.png';
import googleMeet from '../../assets/icons/googleMeet.png';
import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/ru';
import { ToastContainer, toast } from 'react-toastify';
import { PUBLIC_API } from '../../constants/api';

const Index: FC = () => {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        const newValues = {...values}        
        delete newValues.politika
        const date = values?.date_of_visit?.format('YYYY-MM-DD HH:mm')
        const backData = {
            ...newValues,
            date_of_visit: date,
            type_of_appli: '3',
        }
        try {
            const res = await PUBLIC_API.post('partners/application/', backData)
            if (res.data.status === 'CREATED'){
                toast.success(t('VideoConsulting.success'))
            }
        } catch (error) {
            toast.error(t('VideoConsulting.error'))
            return;
        }
    }
    const onFinishFailed = (values: any) => {
        toast.error(t('VideoConsulting.fillFields'))
    }
    return (
  <>
      <Container className={classes.container}>
        <div className={classes.imageBlock}>
          <div>
            <h1 className={classes.headTitle}>
              {t('VideoConsulting.title')}
            </h1>
            <div className={classes.items}>
              <div className={classes.item}>{t('VideoConsulting.descr1')}</div>
              <div className={classes.item}>{t('VideoConsulting.descr2')}</div>
              <div className={classes.item}>{t('VideoConsulting.descr3')}</div>
              <div className={classes.item}>{t('VideoConsulting.descr4')}</div>
            </div>
            <div className={classes.reducer02}>
              <p>{t('VideoConsulting.format')}</p>
              <div className={classes.icons}>
                <img src={zoom} alt="Zoom" />
                <img src={googleMeet} alt="Google Meet" />
              </div>
            </div>
          </div>
          <img src={womanImage} alt="Woman" />
        </div>

        <div className={classes.formWrapper}>
          <h1 className={classes.headTitle}>
            {t('VideoConsulting.getConsult')}
          </h1>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={classes.form}
          >
            <Form.Item
              rules={[{ required: true, message: t('VideoConsulting.enterName') }]}
              name={'name'}
            >
              <Input className={classes.input} placeholder={t('VideoConsulting.name')} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t('VideoConsulting.enterCompany') }]}
              name={'company'}
            >
              <Input className={classes.input} placeholder={t('VideoConsulting.company')} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t('VideoConsulting.enterEmail') }]}
              name={'email'}
            >
              <Input className={classes.input} placeholder={t('VideoConsulting.email')} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t('VideoConsulting.chooseType') }]}
              name={'type_of_video'}
            >
              <Select
                className={classes.select}
                options={[
                  { value: '1', label: 'Zoom' },
                  { value: '2', label: 'Google Meet' },
                ]}
                placeholder={t('VideoConsulting.chooseFormat')}
              />
            </Form.Item>
            <Form.Item name={'comment'}>
              <Input.TextArea className={classes.input} placeholder={t('VideoConsulting.writeMessage')} />
            </Form.Item>
            <div className={classes.date}>
              <p>{t('VideoConsulting.chooseDate')}</p>
              <Form.Item
                rules={[{ required: true, message: t('VideoConsulting.chooseTime') }]}
                name={'date_of_visit'}
              >
                <DatePicker
                  dropdownClassName={classes.dropdown}
                  format={'YYYY-MM-DD HH:mm'}
                  className={classes.datePicker}
                  locale={locale}
                  showTime={{ format: 'HH:mm' }}
                  placeholder={'10.11.2023'}
                />
              </Form.Item>
            </div>
            <div className={classes.bottom}>
              <Form.Item>
                <Button className={classes.submit} type="primary" htmlType="submit">
                  {t('VideoConsulting.chooseConsult')}
                </Button>
              </Form.Item>
              <Form.Item
                name={'politika'}
                valuePropName="checked"
                rules={[{ required: true, message: t('VideoConsulting.checkbox') }]}
              >
                <Checkbox>
                  {t('VideoConsulting.checkbox')}
                </Checkbox>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
    );
};
export default Index;