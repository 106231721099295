import { useEffect, useState } from 'react';
import { currentCurrency } from '../../../constants/api';

const CurrencySelect = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('Киргизский сом, KGS');

  const options = [
    { label: 'Киргизский сом, KGS', value: 'kgs' },
    { label: 'Казахский тенге, KZT', value: 'kzt' },
    { label: 'Российский рубль, RUB', value: 'rub' },
  ];

  const handleOptionClick = (option: any) => {
    setSelectedCurrency(option.label);
    setIsOpen(false);
    localStorage.setItem('curr', option.value)
    window.location.reload()
  };

  useEffect(() => {
    options.forEach((item) => {
      if(item.value === currentCurrency){
        setSelectedCurrency(item.label)
      }
    })
  }, [])

  return (
    <div className="relative inline-block w-64">
      <div
        className="block w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-base cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedCurrency}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 1L5 5L1 1" stroke="#848484"/>
</svg>

        </span>
      </div>

      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className={`cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 ${
                selectedCurrency === option.label ? 'font-semibold text-indigo-600' : 'font-normal'
              }`}
            >
              <span className="block truncate">{option.label}</span>
              {selectedCurrency === option.label && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.62971 0.000144841C8.53628 0.00272379 8.4476 0.0388967 8.38246 0.101L2.88239 5.19582L0.619139 3.09933C0.586 3.06736 0.546306 3.04183 0.502383 3.02425C0.458459 3.00666 0.41119 2.99737 0.363342 2.99692C0.315495 2.99647 0.268031 3.00487 0.22373 3.02162C0.17943 3.03838 0.139184 3.06315 0.105349 3.09449C0.0715135 3.12583 0.0447697 3.16311 0.0266834 3.20415C0.00859704 3.24519 -0.000468191 3.28915 1.86159e-05 3.33348C0.000505422 3.3778 0.0105345 3.42158 0.0295184 3.46227C0.0485024 3.50296 0.0760595 3.53973 0.110576 3.57043L2.62811 5.90246C2.69555 5.96492 2.78702 6 2.88239 6C2.97775 6 3.06922 5.96492 3.13667 5.90246L8.89102 0.572093C8.94299 0.525298 8.97849 0.465051 8.99286 0.399248C9.00723 0.333444 8.9998 0.265154 8.97156 0.203327C8.94331 0.141499 8.89556 0.0890184 8.83456 0.0527622C8.77356 0.0165061 8.70216 -0.00183418 8.62971 0.000144841Z" fill="#006AFF"/>
</svg>

                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CurrencySelect;
