import { FC, useEffect, useState } from "react";
import { useOrder } from "../../store/order/hooks";
import Container from "../../components/footer/Container";
import { fetchOrder } from "../../store/order";
import { useAppDispatch } from "../../store";
import API from "../../constants/api";
import './Basket.css'
import minus from '../../assets/minus.png'
import plus from '../../assets/plus.png'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from "antd";
import { useTranslation } from "react-i18next";



interface IProps{
    img: string
    title: string
    color: string
    price: string
    id: string
    quantity: string
    order: any
}

const NewBasketCard:FC<IProps> = (props) => {
    
    const dispatch = useAppDispatch()

    const {img, title, color, price, id, quantity, order} = props

    const {t} = useTranslation()

    const handleDeleteProductFromCart = async (id: any) => {
        if (!id) return;
        try {
        await API.delete("/orders/cart", {
            params: {
            item_id: id,
            },
        });
        dispatch(fetchOrder() as any);
        } catch (e) {
        console.log(e);
        }
    };


    return(
        <div className="basketWrapper">
            <div className="basketCard">
                <div className="basketImg">
                    <img src={img} alt={title}/>
                </div>
                <div className="basketInfo">
                    <p>{title}</p>
                    <p>Цвет: {color}</p>
                </div>
                <div className="basketCardCount">
                                    <td className="text-sm text-black font-light px-1  whitespace-nowrap">
                                    <div className="flex justify-start items-start align-middle gap-2 py-2">
                                      <button
                                        onClick={() => {
                                          order.items.map((el: any) => {
                                            if (el.id === id) {
                                              API.put("/orders/cart", {
                                                item_id: el.sizes?.[0].id,
                                                quantity:
                                                  Number(el.quantity) - 1,
                                              }).then((res) => {
                                                dispatch(fetchOrder() as any);
                                              });
                                            }
                                          });
                                        }}
                                        className="text-white font-medium text-2xl flex justify-center items-center   bg-[#343434] rounded-full ... h-5 w-5"
                                      >
                                        <img src={minus} alt="" />
                                      </button>
                                      <input
                                        type="text"
                                        className="rounded-full ... border border-solid border-black  px-2 text-sm w-20"
                                        value={quantity}
                                        onChange={(e) => {
                                          let changetItemId = 0;
                                          const updatedItems =
                                            order.items.map(
                                              (el: any) => {
                                                if (el.id !== id) {
                                                  return JSON.parse(
                                                    JSON.stringify(el)
                                                  );
                                                } else {
                                                  changetItemId = el.id;
                                                  return {
                                                    ...el,
                                                    costoms: JSON.parse(
                                                      JSON.stringify(el.costoms)
                                                    ),
                                                    product: JSON.parse(
                                                      JSON.stringify(el.product)
                                                    ),
                                                    quantity: e.target.value,
                                                  };
                                                }
                                              }
                                            );
                                          API.put("/orders/cart", {
                                            item_id: changetItemId,
                                            quantity: e.target.value
                                              ? e.target.value
                                              : 0,
                                          }).then((res) => {
                                            dispatch(fetchOrder() as any);
                                          });
                                        }}
                                      />

                                      <button
                                        onClick={() => {
                                          order.items.map((el: any) => {
                                            if (el.id === id) {
                                              API.put("/orders/cart", {
                                                item_id: el.sizes?.[0].id,
                                                quantity:
                                                  Number(el.quantity) + 1,
                                              }).then((res) => {
                                                dispatch(fetchOrder() as any);
                                              });
                                            }
                                          });
                                        }}
                                        className="text-white font-medium text-2xl flex justify-center items-center   bg-[#343434] rounded-full ... h-5 w-5 "
                                      >
                                        <img src={plus} alt="" />
                                      </button>
                                    </div>
                                  </td>
                </div>
                <div>
                    <p  className="basketCardPrice">{price} c</p>
                </div>
                <div className="basketCardCount" onClick={() => handleDeleteProductFromCart(id)}>
                    X
                </div>
            </div>
            <hr />
        </div>
    )
}


const NewBasket:FC = () => {

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const order = useOrder();

    const {t} = useTranslation()

    useEffect(() => {
      dispatch(fetchOrder() as any);
    }, [])

    const notifyError = () => {
      toast.error('Заполните все поля!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }



    const [payInputs, setPayInputs] = useState({
      city: "",
      address: "",
      refferal_friend_code: "",
      payment_type: "",
      name: "",
      surname: "",
      phone_number: "",
      country: "",
      apartment_num: "",
      postcode: ""
    });

    const handleChangePayInputs = (e: any) => {
      setPayInputs({ ...payInputs, [e.target.name]: e.target.value });
    };

  const handlePay = async () => {
    if (!payInputs.payment_type) {
      notifyError()
      return;
    }
    const refferal_friend_code = payInputs.refferal_friend_code && payInputs.refferal_friend_code;
    try {
      const res = await API.post("/orders/payment", {
        total_amount: order?.total_amount,
        ...payInputs,
        refferal_friend_code,
      });
      if (res.data.payment_url) {
        window.location.href = res.data.payment_url;
        return;
      }
      navigate("/profile/order-history");
    } catch (e) {
      console.log(e);
    }
  };

  const [step, setStep] = useState('basket')

const payment = (
  <>
    <p className="basketTitle">{t('Basket.title')}</p>
    <p className="basketMobTitle basketMobTitle1">{t('Basket.mobTitle')}</p>
    <p className="basketMobTitle">{t('Basket.amount')} {order?.items?.length}</p>
    <p className="basketMobTitle basketMobTitle2">{t('Basket.cost')} {order?.total_amount} с</p>
    <hr className="basketMobTitle basketMobTitle2"/>
    <div className="basketPaymentWrapper">
      <div>
        <div className="basketPaymentInputsWrapper">
          <p className="basketPaymentInputsTitle">{t('Basket.contacts')}</p>
          <div className="basketPaymentInputs">
            <Input onChange={handleChangePayInputs} style={{marginRight: '10px'}} name="name" placeholder={t('name')} />
            <Input onChange={handleChangePayInputs} name="surname" placeholder={t('surname')} />
          </div>
          <Input onChange={handleChangePayInputs} name="email" placeholder="email" />
          <Input onChange={handleChangePayInputs} name="phone_number" placeholder={t('phone_number')} />
        </div>
        <div className="basketPaymentInputsWrapper">
          <p className="basketPaymentInputsTitle">{t('Basket.address')}</p>
          <div className="basketPaymentInputs">
            <Input onChange={handleChangePayInputs} style={{marginRight: '10px'}} name="country" placeholder={t('country')} />
            <Input onChange={handleChangePayInputs} name="city" placeholder={t('city')} />
          </div>
          <Input onChange={handleChangePayInputs} name="address" placeholder={t('address')} />
          <div className="basketPaymentInputs">
            <Input onChange={handleChangePayInputs} style={{marginRight: '10px'}} name="apartment_num" placeholder={t('apartment_num')} />
            <Input onChange={handleChangePayInputs} name="postcode" placeholder={t('postcode')} />
          </div>
        </div>
        <div className="basketPaymentInputsWrapper">
          <p className="basketPaymentInputsTitle" style={{marginTop: '60px'}}>{t('Basket.payment')}</p>
          <div className="flex justify-start">
            <div className="form-check form-check-inline">
              <input
                className="rounded-sm h-4 w-4 border border-red-600 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="payment_type"
                id="inlineRadio1"
                value="2"
                onChange={handleChangePayInputs}
              />
              <label className="form-check-label inline-block text-gray-800" htmlFor="inlineRadio10">
                {t('Basket.VISA')}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="payment_type"
                id="inlineRadio2"
                value="1"
                onChange={handleChangePayInputs}
              />
              <label className="form-check-label inline-block text-gray-800" htmlFor="inlineRadio20">
                {t('Basket.money')}
              </label>
            </div>
          </div>
          <p style={{maxWidth: '70%'}}>{t('Basket.warn')}</p>
        </div>
        <div onClick={handlePay} style={{width: '100%', backgroundColor: '#020202', marginTop: '40px', color: '#fff', cursor: 'pointer', padding: '13px 88px', display: 'flex', justifyContent: 'center', borderRadius: '40px', marginBottom: '40px'}}>
          {t('Basket.payNow')}
        </div>
      </div>
      <div>
        <p className="basketPaymentInputsTitle basketCardCount">{t('Basket.order')}</p>
        <div className="basketPaymentCards basketCardCount">
          {order?.items?.map((item: any) => (
            <>
              <div className="basketPaymentCardWrapper">
                <div className="basketPaymentCard" key={item.id}>
                  <img src={item.product.images.small} alt="" />
                </div>
                <div className="basketPaymentCardInfo">
                  <div>
                    <p>{item.product.name}</p>
                    <p>{item.amount} c</p>
                  </div>
                  <p>{t('Basket.endAmount')} {item.quantity}</p>
                </div>
              </div>
              <hr />
            </>
          ))}
        </div>
        <div className="basketPaymentTotal basketCardCount">
          <p className="basketPaymentInputsTitle">{t('Basket.end')}</p>
          <p className="basketPaymentInputsTitle">{order?.total_amount} c</p>
        </div>
      </div>
    </div>
  </>
)


const basket = (
  <div>
    <p className="basketTitle">{t('Basket.basket')}</p>
    <div className="basketTable basketCardCount">
      <p>{t('Basket.item')}</p>
      <p>{t('Basket.itemDescr')}</p>
      <p>{t('Basket.itemAmount')}</p>
      <p>{t('Basket.itemPrice')}</p>
      <p>{t('Basket.delete')}</p>
    </div>
    <hr />
    {order?.items?.map((item: any) => (
      <div key={item.id}>
        <NewBasketCard 
          order={order}
          img={item.product.images.small}
          title={item.product.name}
          price={item.amount}
          color={item.product?.color}
          id={item.id}
          quantity={item.quantity}
        />
      </div>
    ))}
    <div className="basketPay">
      <Input
        type="text"
        className="basketCode"
        placeholder={t('Basket.enterCouponCode')} // Assuming you'll add this translation key
        bordered={false}
        name="refferal_friend_code"
        value={payInputs.refferal_friend_code}
        onChange={handleChangePayInputs}
      />
      <div className="basketAmount">
        <div>
          <p>{t('Basket.end')}</p>
          <p>{order?.total_amount} c</p>
        </div>
        {order?.total_amount ? (
          <div onClick={() => setStep('payment')}>
            {t('Basket.check')}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  </div>
)



  return (
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
          {step === 'basket' ? basket : payment}
        </Container>
    )
}


export default NewBasket