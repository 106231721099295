import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { PUBLIC_API } from "../../../constants/api";
import classes from "./index.module.scss";
import Container from "../../../components/footer/Container";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";

const getDataBlog = async (id: string) => {
  try {
    const res: AxiosResponse<any> = await PUBLIC_API.get(`blog/news/${id}`);
    return res.data;
  } catch {
    return null;
  }
};
const BlogDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>();
  useEffect(() => {
    (async () => {
      const data = await getDataBlog(id || "");
      setData(data);
    })();
  }, []);

  const { t } = useTranslation();
  return (
    <Container>
      <div className={classes.wrapper}>
        <div className={classes.images}>
          <div className={classes.mainImage}>
            <img
              className={classes.mainSourceImage}
              src={data?.data?.picture}
              alt={"main product"}
            />
          </div>
        </div>
        <div className={classes.blogContent}>
          <div className={classes.blogText}>
            <p className={classes.headTitleTitleBlog}>{data?.data?.title}</p>
            {data ? Parser(data?.data?.content) : <></>}
            <p className={classes.briefAbout}>{data?.data?.brief_about}</p>
            <p className={classes.date}>
              {new Date(data?.data?.created_at).toLocaleDateString()}
            </p>
          </div>

          <div>
            <p className={classes.more}>{t("Blog.look")}</p>
            {data?.similar_news?.map((item: any) => (
              <a href={`/blog/${item.slug}`}>
                <div className={classes.simular}>
                  <img src={"https://suveniry-mmt.kz/" + item?.picture} />
                  <p className={classes.simularTitle}>{item?.title}</p>
                  <p className={classes.simularDesc}>{item?.brief_about}</p>
                  <p className={classes.simularMore}>{t("Blog.more")}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
        {/* <div className={classes.blocks}>
        <div className={classes.item}>
            <p className={classes.headtitle}>Клиент</p>

            <div className={classes.infoItem}>
            Ведущая компания на рынке сувенирной продукции, Ваш партнер, консультант и помощник
            </div>
        </div>  
        <div className={classes.item}>
            <p className={classes.headtitle}>Решение</p>

            <div className={classes.infoItem}>
            Ведущая компания на рынке сувенирной продукции, Ваш партнер, консультант и помощник в сфере брендированных подарков и рекламной продукции. Мы – фанаты сувенирной индустрии! Наши светлые головы работают на Ваш бизнес. Мы – созидатели. Творим от создания концепта до полного воплощения проекта в жизнь. Мы – производители. Произведем то, чего еще нет в природе. А еще, мы – немножечко волшебники. Наша работа – создавать красоту, хорошее настроение и классные подарки!
            </div>
        </div>
        <div className={classes.item}>
            <p className={classes.headtitle}>Задача</p>
            <div className={classes.infoItem}>
                Ведущая компания на рынке сувенирной продукции, Ваш партнер, консультант и помощник в сфере брендированных подарков и рекламной продукции. Мы – фанаты сувенирной индустрии! Наши светлые головы работают на Ваш бизнес. Мы – созидатели. Творим от создания концепта до полного воплощения проекта в жизнь. Мы – производители. Произведем то, чего еще нет в природе.
            </div>
        </div>
        <div className={classes.item}>
            <p className={classes.headtitle}>Остались вопросы?</p>
            <div className={'portfolio_faq'}>
                        <FaqForm showQuestion={true} showTitle={false}/>
                    </div>
        </div>
    </div> */}
      </div>
    </Container>
  );
};
export default BlogDetail;
