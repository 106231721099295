import { FC, ReactNode } from "react";
import classes from './index.module.scss'

interface IContainerProps{
    children: ReactNode,
    id?: string,
    className?: string,
}

const Container:FC<IContainerProps> = ({children, id, className}) => {

    return(
        <div id={id} className={`${classes.container} ${className ? className : ''}`}>
            {children}
        </div>
    )
}

export default Container;