import elcart from './icons/Элкарт.svg';
import balance from './icons/Balance.svg';
import freedom from './icons/Freedom_Pay.svg';
import MC from './icons/MC.svg';
import megaPay from './icons/MegaPay.svg';
import onoi from './icons/Onoi.svg';
import o from './icons/ОДеньги.svg';
import elsom from './icons/Элсом.svg';
import maestro from './icons/Maestro.svg';
import mbank from './icons/Mbank.svg';
import umai from './icons/Umai.svg';
import visa from './icons/VisaPartner.svg';

import classes from './PaymentMethods.module.scss'
import { useTranslation } from 'react-i18next';


import { FC } from 'react';


const partnersArr = [
  {
    img: balance,
    alt: 'баланс',
  },
  {
    img: freedom,
    alt: 'фридомпэй',
  },
  {
    img: elcart,
    alt: 'элкарт',
  },
  {
    img: megaPay,
    alt: 'мегапэй',
  },
  {
    img: onoi,
    alt: 'оной',
  },
  {
    img: MC,
    alt: 'мастеркард',
  },
  {
    img: maestro,
    alt: 'маэстро',
  },
  {
    img: visa,
    alt: 'виза',
  },
  {
    img: mbank,
    alt: 'мбанк',
  },
  {
    img: elsom,
    alt: 'элсом',
  },
  {
    img: o,
    alt: 'о деньги!',
  },
  {
    img: umai,
    alt: 'умай',
  },
];


const PaymentMethods:FC = () => {
const {t} = useTranslation()

  return (
    <div className={classes.wrapper}>
      <p>{t('Payment.title')}</p>
      <div className={classes.partnersBlock}>
        {partnersArr.map((item, i) => (
          <img src={item.img} alt={item.alt} key={i}/>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;
