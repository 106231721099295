import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import store from "../store";
import About from "./About";
import CartFull from "./basket/cart/cartFull/CartFull";
import Blog from "./blog";
import BlogDetail from "./blog/blogDetail";
import Profile from "./profile";
import SignInPage from "./signin";
import SignUpPage from "./signup";
import Portfolio from "./NewPortfolio";
import Recovery from "./signin/Recovery";
import Confirmation from "./signup/confirmation/Confirmation";
import EditProfile from "./profile/editProfile/EditProfile";
import NewPassword from "./signup/newPassword/NewPassword";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import MainPage from "./Main";
import Footer from "../components/NewFooter";
import Contacts from "./Contacts";
import Catalog from "./NewCatalog";
import Lookbook from "./Lookbook";
import Product from "./Product";
import NewBasket from "./NewBasket";
import PortfolioDetail from "./PortfolioDetail/PortfolioDetail";
import VideoConsulting from './videoConsulting/index'

function App() {

  
  const Wrapper = ({ children}: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
    <div style={{boxSizing: 'border-box'}}>
      <Provider store={store}>
        <BrowserRouter>
        <Wrapper>
          <Header />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/contacts" element={<Contacts />}/>
            <Route path="/about" element={<About />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/portfolio/:id" element={<PortfolioDetail />} />
            <Route path="/cart" element={<NewBasket />} />
            <Route path="/cartfull" element={<CartFull />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/create-password" element={<NewPassword />} />
            <Route path="/recovery" element={<Recovery />} />
            <Route path="/catalog" element={<Catalog />}/>
            <Route path="/new-password" element={<NewPassword />} />

            {/* <Route
              path="/catalog-detailoasis/:id"
              element={<CatalogDetailsOasis />}
            />
            <Route
              path="/catalog-detailhiidea/:id"
              element={<CatalogDetailsHiidea />}
            /> */}

            <Route path="/product/:id" element={<Product />}/>


            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            {/* <Route path="*" element={<Redirect />} /> */}
            <Route path="/lookbook" element={<Lookbook />} />
            <Route path="/videoConsulting" element={<VideoConsulting />} />

          </Routes>
          <Footer />
          </Wrapper>
        </BrowserRouter>
      </Provider>
      </div>
    </>
  );
}

export default App;
