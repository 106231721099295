import { FC, useState } from "react";
import './Special.css'
import { useNavigate } from "react-router-dom";
import one from './Group122.png'
import two from './Group123.png'
import three from './Group124.png'
import ModalWomanBruhForm from "../../../components/ModalWomanBruhForm/ModalWomanBruhForm";
import { useTranslation } from "react-i18next";
interface ISpecialItemProps{
    title: string
    description: string
    action: () => void
    actionTitle: string
    background: string
}

const SpecialItem:FC<ISpecialItemProps> = (props) => {

    const {title, description, action, actionTitle, background} = props
    
    return(
        <div style={{background: `url(${background}) right no-repeat`, backgroundSize: 'cover'}} className="specialItem">
            <p className="specialItemTitle">{title}</p>
            <p className="specialItemDesc">{description}</p>
            <p className="specialItemAction" onClick={() => action()}>{actionTitle}</p>
        </div>
    )
}

const Special:FC = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);

    const {t} = useTranslation()
    const specialArr = [
        {
            title: t('Special.contentTitle1'),
            description:  t('Special.contentDescr1'),
            action: () => setOpen2(true),
            actionTitle: t('Special.more'),
            background: three
        },
        {
            title:  t('Special.contentTitle2'),
            description:  t('Special.contentDescr2'),
            action: () => setOpen(true),
            actionTitle: t('Special.invite'),
            background: one
        },        
        {
            title: t('Special.contentTitle3'),
            description: t('Special.contentDescr3'),
            action: () => navigate('/videoConsulting'),
            actionTitle: t('Special.putCall'),
            background: two
        }
    ]

    return (
    <>
        <div className="specialWrapper">
            <p className='specialTitle'>{t('Special.title')}</p>
            <div className="specialBlock">
                {specialArr.map((item) => (
                    <SpecialItem 
                        key={item.title}
                        title={item.title}
                        description={item.description}
                        action={item.action}
                        actionTitle={item.actionTitle}
                        background={item.background}
                    />
                ))}
            </div>
        </div>
        <ModalWomanBruhForm type_of_appli={'1'} open={open} handleClose={handleClose} title={t('Special.modal1')}/>
        <ModalWomanBruhForm type_of_appli={'2'} open={open2} handleClose={handleClose2} title={t('Special.modal2')}/>
    </>
    )
}

export default Special;